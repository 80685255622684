
const prod = {
  URL: 'https://acls-jaggaer.unilab.com.au',
  API_URL: 'https://acls-jaggaer.unilab.com.au/api'
};

const dev = {
  URL: 'https://acls-jaggaer.unilab.com.au',
  API_URL: 'https://acls-jaggaer.unilab.com.au/api-dev'
};

// automatically export dev or prod variables
export const config = process.env.NODE_ENV === 'development' ? dev : prod;