import React from 'react';
import './App.css';

import { InventoryTable } from './components/Inventory.js';

import { Spin } from 'antd';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import axios from 'axios';

import { config } from './Config.js'


class AppContainer extends React.Component {
  
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <InventoryTable/>
    )
  }
}

class App extends React.Component {

  render() {
    return(
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route 
              path="/*" 
              element={
                <AppContainer />
              }
            />
          </Routes>
        </BrowserRouter>
        
      </div>
    );
  }
}


export default App;