import React from 'react';
import moment from 'moment';
import axios from 'axios';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';


import { 
  Layout, 
  Table,
  Checkbox,
  Divider,
  Select,
  Button,
  Pagination,
  Radio,
  Collapse,
  Input,
  
} from 'antd';

import { 
  SearchOutlined,
  MenuFoldOutlined, 
  MenuUnfoldOutlined, 
} from '@ant-design/icons';

import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';

import { config } from '../Config.js'

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;


function makeColComparator (property) {
  return (a, b) => {
    if (a[property] === null || a[property] === "") {
      return 1;
    } else if (b[property] === null || b[property] === "") {
      return -1;
    } else {
      return a[property].localeCompare(b[property]);
    }
  }
}



class InventoryTable extends React.Component {

  constructor(props) {
    super(props);

    let windowUrlParams = window.location.search;

    // some web browsers can't parse the leading question mark delimiter correctly, so manually parse it
    if (windowUrlParams.substring(0,1) == '?') {
      windowUrlParams = windowUrlParams.slice(1);
    } else {
      // default to nothing if no question mark delimiter found
      windowUrlParams = "";
    }

    const params = new URLSearchParams(windowUrlParams);
    const accessKey = params.get('access');
    let searchTokens = params.get('token');
    if (searchTokens != null && searchTokens != '') {
      searchTokens = searchTokens.split(',');
    }
    const kiosk = params.get('kiosk') == 'true' ? true : false;
    const title = params.get('title') == 'false' ? false : true;

    // mapping between internal name and display name
    this.aliasMap = {
      materialName: "Material Name",
      room: "Room",
      sublocation: "Sublocation",
      amount: "Amount",
      owner: "Owner",
      siteAcquiredDate: "Site Acquired Date",
      brand: "Brand",
      barCode: "Bar Code",
      catalogNo: "Catalog #",
      primaryTransportHazard: "Primary Transport Hazard",
    };

    this.state = {

      kiosk: kiosk,
      oskMode: "default",
      title: title,

      accessKey: accessKey,
      searchTokens: searchTokens,

      kioskSidebarExpand: false,

      // text contained by the filter search bars, which don't filter content until the search button is pressed
      filters: {
        materialName: "",
        room: "",
        sublocation: "",
        amount: "",
        owner: "",
        brand: "",
        barCode: "",
        catalogNo: "",
        primaryTransportHazard: "",
      },

      // 'active' filters that affect what rows are shown
      activeFilters: {
        materialName: "",
        room: "",
        sublocation: "",
        amount: "",
        owner: "",
        brand: "",
        barCode: "",
        catalogNo: "",
        primaryTransportHazard: "",
      },

      // the currently selected text box - so the osk knows what string is being modified
      selectedInput: null,

      // table data
      dataSource: [],
      dataLoading: false,

      // report date
      reportDate: null,
    };

  }

  componentDidMount() {
    this.apiGetTable()
  }


  // query database using state parameters
  apiGetTable() {
    if (this.state.accessKey == null || this.state.searchTokens == null) {
      return;
    }

    // set loading status on table while new data is being fetched. 
    this.setState({ dataLoading: true});

    let requests = []

    let request = config.API_URL + "/inventory/?";
    request += '&access=' + this.state.accessKey;
    request += '&token=' + this.state.searchTokens.join(',');
    requests.push(axios.get(request))

    let dateRequest = config.API_URL + "/date/?";
    dateRequest += '&access=' + this.state.accessKey;
    requests.push(axios.get(dateRequest))


    Promise.all(requests)
    .then(resultArray => {

      let resultDataSource = resultArray[0]
      let mainDataSource = null
      if (resultDataSource && resultDataSource.headers["content-type"] && resultDataSource.headers["content-type"].indexOf("application/json") !== -1) {
        mainDataSource =  resultDataSource.data
      }

      let resultDate = resultArray[1]
      let mainDate = null
      if (resultDate && resultDate.headers["content-type"] && resultDate.headers["content-type"].indexOf("application/json") !== -1) {
        mainDate =  resultDate.data
      }

      this.setState({ dataSource: mainDataSource, reportDate: mainDate, dataLoading: false });

    })
  }

  // overwrite the active filters with the content in the current filters
  setActiveFilters = () => {
    let stateCopy = JSON.parse(JSON.stringify(this.state.filters))
    this.setState({activeFilters: stateCopy})
  }

  clearActiveFilters = () => {
    let filtersCopy = JSON.parse(JSON.stringify(this.state.filters))
    Object.keys(filtersCopy).forEach(function(key, index) {
      filtersCopy[key] = "";
    });

    let activeFiltersCopy = JSON.parse(JSON.stringify(this.state.activeFilters))
    Object.keys(activeFiltersCopy).forEach(function(key, index) {
      activeFiltersCopy[key] = "";
    });
    this.setState({filters: filtersCopy, activeFilters: activeFiltersCopy})
  }

  // manually process keyboard inputs
  onKeyPress = (button) => {
    console.log("Button pressed", button);

    let filtersCopy = JSON.parse(JSON.stringify(this.state.filters))

    if (button === "{shift}" || button === "{lock}") {
      const newMode = this.state.oskMode === "default" ? "shift" : "default";
      this.setState({oskMode: newMode});
      return;
    }


    if (button === "{bksp}") {
      filtersCopy[this.state.selectedInput] = filtersCopy[this.state.selectedInput].slice(0, -1);
    } else if (button === "{space}") {
      filtersCopy[this.state.selectedInput] = filtersCopy[this.state.selectedInput] + " ";
    } else {
      filtersCopy[this.state.selectedInput] = filtersCopy[this.state.selectedInput] + button
    }
    this.setState({filters: filtersCopy})

    
  };




  render() {


    if (this.state.accessKey == null || this.state.searchTokens == null) {
      return(
        <div className="p-20">
          Invalid Authentication
        </div>  
      );
    }


    const columns = [
      {
        title: 'Material Name',
        dataIndex: 'materialName',
        key: 'materialName',
        sorter: makeColComparator("materialName"),
      },
      {
        title: 'Room',
        dataIndex: 'room',
        key: 'room',
        sorter: makeColComparator("room"),
      },
      {
        title: 'Sublocation',
        dataIndex: 'sublocation',
        key: 'sublocation',
        sorter: makeColComparator("sublocation"),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: makeColComparator("amount"),
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
        sorter: makeColComparator("owner"),
      },
      {
        title: 'Site Acquired Date',
        dataIndex: 'siteAcquiredDate',
        key: 'siteAcquiredDate',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        sorter: makeColComparator("brand"),
      },
      {
        title: 'Bar Code',
        dataIndex: 'barCode',
        key: 'barCode',
        sorter: makeColComparator("barCode"),
      },
      {
        title: 'Catalog #',
        dataIndex: 'catalogNo',
        key: 'catalogNo',
        sorter: makeColComparator("catalogNo"),
      },
      {
        title: 'Primary Transport Hazard',
        dataIndex: 'primaryTransportHazard',
        key: 'primaryTransportHazard',
        sorter: makeColComparator("primaryTransportHazard"),
      },
    ];

    // set up filter search bars
    var tableFilters = [];
    for (let prop in this.state.filters) {
      const fixedProp = prop;
      tableFilters.push(
          <div className="flex-wrap m-2">
            <span className="">{this.aliasMap[fixedProp]}</span>
            <br/>
            <Input
              allowClear
              value={this.state.filters[fixedProp]}
              style={{ width: '200px', margin: '5px'}}
              placeholder={"Search for "+this.aliasMap[fixedProp]+"..."}
              onFocus={
                () => {
                  this.setState({selectedInput: fixedProp})
                }
              }
              onChange={
                e => {
                  let stateCopy = JSON.parse(JSON.stringify(this.state.filters))
                  stateCopy[fixedProp] = e.target.value;      
                  this.setState({filters: stateCopy})
                }
              }
            >
            </Input>
          </div>
        );
    }



    // filter dataSource rows, removing anything that doesn't match the selection boxes
    let filteredDataSource = this.state.dataSource;
    for (let prop in this.state.activeFilters) {
      if (this.state.activeFilters[prop] != "") {
        filteredDataSource = filteredDataSource.filter(row => {
          if (row[prop] == null) {
            return false;
          }
          return (row[prop].toLowerCase().includes(this.state.activeFilters[prop].toLowerCase()))
        })
      }
    }


    if (this.state.kiosk == true) {


      let kioskSideBar = null;
      let rightWidth = null;
      if (this.state.kioskSidebarExpand == false) {

        rightWidth = "calc(100% - 50px)";
        kioskSideBar =               
          <div className=""  style={{"width": "40px", "position": "relative"}}>
            <Button 
              style={{
                "position": "absolute", 
                "top": "0px", 
                "left": "0px", 
                "scale": "150%",
                "height": "70px", 
                "width": "50px"
              }}
              className="place-content-center"
              type="text"
              onClick={e => this.setState({kioskSidebarExpand: true})}
            >
              <MenuUnfoldOutlined/>
              <br/>
              <SearchOutlined />
            </Button>
          </div>

      } else {

        rightWidth = "70%";
        kioskSideBar = 
          <div className="h-screen float-left" style={{"width": "30%"}}>
            <div style={{"height": "100%"}}>
              <div className="overflow-auto relative" style={{height: 'calc(100% - 280px)'}} >
                <div>
                  {this.state.title != false &&
                    <div className="mb-5 mt-2">
                      <span className="text-2xl"> 
                        Jaggaer Chemical Inventory
                      </span>
                    </div>
                  }
                  {this.state.reportDate != null && 
                    <div className="mb-2 mt-3 text-center">
                      <span className=""> 
                        Report Date: {this.state.reportDate}
                      </span>
                    </div>
                  }
                  <div className="mt-5 ml-2 mr-2 mb-10 bg-white">
                  <span className="font text-lg">Search for a specific field:</span>
                    <div className="m-5 flex">
                      <div className="flex flex-wrap place-content-center">
                        {tableFilters}
                      </div>
                    </div>
                  </div>

                  <Button 
                    style={{
                      "position": "absolute", 
                      "top": "0px", 
                      "right": "15px", 
                      "scale": "150%", 
                      "height": "70px", 
                      "width": "50px",
                      "margin": "auto",
                    }}
                    type="text"
                    onClick={e => this.setState({kioskSidebarExpand: false})}
                  >
                    <MenuFoldOutlined/>
              <br/>
              <SearchOutlined />
                  </Button>

                </div>
              </div>
              <div>
                <div className="border-solid border-t-2  border-b-2 border-gray-300" style={{"height": "50px"}}>
                  <div className="p-2">
                    <Button type="secondary" className="mr-10" onClick={this.clearActiveFilters}>Clear All</Button>
                    <Button type="primary" onClick={this.setActiveFilters}>Search</Button>
                  </div>
                </div>
                  <Keyboard
                    onKeyPress={this.onKeyPress}
                    layoutName={this.state.oskMode}
                    layout={
                      // keyboard layout that removes keys that aren't needed for this application
                      {
                        'default': [
                          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                          'q w e r t y u i o p [ ] \\',
                          '{lock} a s d f g h j k l ; \'',
                          '{shift} z x c v b n m , . / {shift}',
                          '{space}'
                        ],
                        'shift': [
                          '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
                          'Q W E R T Y U I O P { } |',
                          '{lock} A S D F G H J K L : "',
                          '{shift} Z X C V B N M &lt; &gt; ? {shift}',
                          '{space}'
                        ]
                      }
                  }
                  />
              </div>
            </div>
          </div>

      }




      // render kiosk appearance
      return(
        <Layout className="site-layout animate-fade-in">
          <Content className="bg-white">
            <div className="bg-white min-h-360">

              {kioskSideBar}

              <div className="h-screen float-right overflow-auto"  style={{"width": rightWidth}}>
                <div className="bg-white border-solid border-2 border-gray-100">
                  <Table 
                    dataSource={filteredDataSource} 
                    columns={columns} 
                    size="small"
                    pagination={{ pageSize: 50}}
                    loading={this.state.dataLoading}
                  />
                </div>
              </div>


            </div>
          </Content>
        </Layout>
      );
    } else {

      // render default appearance
      return(
        <Layout className="site-layout animate-fade-in">
          <Content className="bg-white">
            <div className="bg-white p-7 min-h-360">              
              
              {this.state.title != false &&
                <div className="mb-4">
                  <span className="text-2xl"> 
                    Jaggaer Chemical Inventory
                  </span>
                </div>
              }

              <div className="h-full ml-10 mr-10 mb-5">
                <Collapse>

                  <Panel header="Click to search for a specific field:" key="1">
                    <div className="mb-2 mr-3 ml-3 flex">
                      <div className="flex flex-wrap justify-center">
                        {tableFilters}
                      </div>
                    </div>
                    <Button type="secondary" size="large" className="mr-10" onClick={this.clearActiveFilters}>Clear All Filters</Button>
                    <Button type="primary" size="large" onClick={this.setActiveFilters}>Apply Search Filters</Button>

                  </Panel>
                </Collapse>

              </div>


              {this.state.reportDate != null && 
                <div className="ml-10 mb-2 text-left">
                  <span className=""> 
                    Report Date: {this.state.reportDate}
                  </span>
                </div>
              }
              <div className="bg-white ml-10 mr-10 border-solid border-2 border-gray-100">
                <Table 
                  dataSource={filteredDataSource} 
                  columns={columns} 
                  size="small"
                  pagination={{ pageSize: 100}}
                  loading={this.state.dataLoading}
                />
              </div>
            </div>
          </Content>
        </Layout>
      );

    }
  }
}


export {
  InventoryTable,
}